import FilterData from './FilterData';
import CalculateTotals from './CalculateTotals';
import CalculateTotalsMeasures from './CalculateTotalsMeasures';
import CalculateTableData from './CalculateTableData';
import UpdatePhasing from './UpdatePhasing';

const UpdateData = (prev, map, assumptions) => {
    let geoSelected = map.mapSelected;
    let measuresSelected = assumptions.measuresSelected;
    let filteredData = FilterData(geoSelected, measuresSelected, prev.dataLsoaFull);

    // Handle empty postcode selection (which equals all selected) - selectedPostcodes.length === 0
    // const geoSelectedPC = Object.values(prev.dataPostcode).filter(f => {return(assumptions.summary.selectedPostcodes.includes(f.postcode) ? f.lsoa : false)});
    let filteredDataPC = FilterData(geoSelected, measuresSelected, prev.dataPostcodeFull);

    let dataTotals = CalculateTotals(filteredData, assumptions, 100);
    prev.dataLsoa = filteredData;
    prev.dataPostcode = filteredDataPC;
    prev.dataTotals = dataTotals;
    prev.dataTotalsOpportunityMeasure = CalculateTotalsMeasures(filteredData, assumptions, 100);
    prev.dataTable = CalculateTableData(filteredDataPC, assumptions, CalculateTotalsMeasures);

    let selectedPostcodes = assumptions.summary.selectedPostcodes;
    if(selectedPostcodes.length === 0){
        prev.dataFinal = CalculateTableData(filteredDataPC, assumptions, CalculateTotalsMeasures);
        prev.dataTotalsMeasure = CalculateTotalsMeasures(filteredData, assumptions, assumptions.summary.uptake);
        prev.dataTotalsKPI = CalculateTotals(filteredDataPC, assumptions, assumptions.summary.uptake);
    } else {
        let geoFiltered = Object.values(filteredDataPC).filter(f => {return(selectedPostcodes.includes(f.postcode) ? f.lsoa : false)});
        prev.dataFinal = CalculateTableData(geoFiltered, assumptions, CalculateTotalsMeasures);
        prev.dataTotalsMeasure = CalculateTotalsMeasures(geoFiltered, assumptions, assumptions.summary.uptake);
        prev.dataTotalsKPI = CalculateTotals(geoFiltered, assumptions, assumptions.summary.uptake);
    };

    prev.timeline = UpdatePhasing(prev, assumptions);

    return prev;
};

export default UpdateData;