const FilterData = (geoSelected, measuresSelected, data) => {

    const geoFiltered = Object.values(data).filter(f => {return(geoSelected.includes(f.lsoa) ? f.lsoa : false)});
        
    let measureFiltered = Object.values(geoFiltered)
        .filter((element) => 
            element.measures.some((subElement) => measuresSelected.includes(subElement.id)))
        .map(element => {
            return Object.assign({}, element, {measures : element.measures.filter(subElement => measuresSelected.includes(subElement.id))})
        });

    let totalCosts = Object.values(measureFiltered).map((lsoa) => {
        let measures = lsoa.measures;
        lsoa.totalCost = measures.reduce((cost, measure) => {
            cost += measure.cost
            return cost;
        }, 0)
        return lsoa
    })
        
    return totalCosts;
}

export default FilterData;
