import React, { useContext } from 'react';
import Style from './Finance.module.css';
import { Row, Col } from 'react-bootstrap';

import {AssumptionsContext} from '../../../../context/AssumptionsContext';
import {MapContext} from '../../../../context/MapContext';
import {DataContext} from '../../../../context/DataContext';

import UpdateData from '../../../../logic/UpdateData';

import Slider from '@material-ui/core/Slider';
      
function valuetext(value) {
    return `${value}%`;
}

// Slider components - to be moved to layout
const MaterialSlider = (props) => {
    let {name, disabled, value, handler} = props;
    return(
        <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handler(name)}
            aria-labelledby="input-slider"
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            disabled={disabled}
        />
    )
}

const Finance = () => {

    const [assumptions, setAssumptions] = useContext(AssumptionsContext);
    const [data, setData] = useContext(DataContext);
    const [map] = useContext(MapContext);

    const financeHandler = event =>  {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // Update assumptions context        
        setAssumptions(prev => {
            prev.summary[name] = Number(value);            
            return ({...prev})
        });

        // Update data context
        let dataUpdated = UpdateData(data, map, assumptions);
        setData(dataUpdated);
    };

    const sliderHandler = name => (event, newValue) => {
        setAssumptions(prev => {
            prev.summary[name] = newValue;
            return({...prev});
        });
        
        let dataUpdated = UpdateData(data, map, assumptions);
        setData(dataUpdated);
    }

    const uptakeHandler = name => (event, newValue) => {
        //if(['managementCosts', 'contingencyCosts'].includes(name))
        let owner = 0;
        let other = 0;
        let uptake = 0;
        if (name === 'ownerFunding') {
            owner = newValue;
            other = newValue + assumptions.summary.otherFunding > 100 ? assumptions.summary.otherFunding - (newValue + assumptions.summary.otherFunding  - 100) : assumptions.summary.otherFunding;
            uptake = assumptions.summary.uptake;
        } else if(name === 'otherFunding') {
            owner = newValue + assumptions.summary.ownerFunding > 100 ? assumptions.summary.ownerFunding - (newValue + assumptions.summary.ownerFunding  - 100) : assumptions.summary.ownerFunding;
            other = newValue;
            uptake = assumptions.summary.uptake;
        } else {
            owner = assumptions.summary.ownerFunding;
            other = assumptions.summary.otherFunding;
            uptake = newValue;
        }
        let resident = 100 - owner - other;

        let values = {
            uptake: uptake,
            ownerFunding: owner,
            otherFunding: other,
            residentFunding: resident
        };
        //setSliderValues(values);

        setAssumptions(prev => {
            prev.summary.uptake = values.uptake;
            prev.summary.ownerFunding = values.ownerFunding;
            prev.summary.otherFunding = values.otherFunding;
            prev.summary.residentFunding = values.residentFunding;
            return ({...prev})
        });

        // Update data context
        let dataUpdated = UpdateData(data, map, assumptions);
        setData(dataUpdated);
    }

    return(
        <div className={Style.assumptionsContainer}>
            <form>
                <fieldset>
                    <legend><h3>Step 6 - Set resident uptake</h3></legend>
                    <Row className={Style.inputRow}>
                        <Col xs={4}>
                            <label htmlFor='uptake' className={Style.label}>
                                Resident uptake: {assumptions.summary.uptake}%
                                <MaterialSlider name='uptake' disabled={false} value={assumptions.summary.uptake} handler={uptakeHandler}/>
                            </label>
                        </Col>
                    </Row>
                </fieldset>
                <fieldset>
                    <legend><h3>Step 7 - Set extra costs and funding sources</h3></legend>
                    <Row className={Style.inputRow}>
                        <Col xs={4}>
                            <label htmlFor='auxiliaryCost' className={Style.label}>
                                Auxiliary costs
                                <span className={Style.inputCurrency}>
                                    <input
                                        type="number"
                                        name='auxiliaryCost'
                                        value={assumptions.summary.auxiliaryCost.toString()}
                                        className={Style.input}
                                        onChange={financeHandler}
                                    />
                                </span>
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label htmlFor='managementCost' className={Style.label}>
                                Management fee (% of total): {assumptions.summary.managementCost}%
                                <MaterialSlider name='managementCost' disabled={false} value={assumptions.summary.managementCost} handler={sliderHandler}/>
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label htmlFor='contingencyCost' className={Style.label}>
                                Contingency (% of total): {assumptions.summary.contingencyCost}%
                                <MaterialSlider name='contingencyCost' disabled={false} value={assumptions.summary.contingencyCost} handler={sliderHandler}/>
                            </label>
                        </Col>
                    </Row>
                    <Row className={Style.inputRow}>
                        <Col xs={4}>
                            <label htmlFor='ownerFunding' className={Style.label}>
                                Lead organisation funding: {assumptions.summary.ownerFunding}%
                                <MaterialSlider name='ownerFunding' disabled={false} value={assumptions.summary.ownerFunding} handler={uptakeHandler}/>
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label htmlFor='otherFunding' className={Style.label}>
                                Other funding: {assumptions.summary.otherFunding}%
                                <MaterialSlider name='otherFunding' disabled={false} value={assumptions.summary.otherFunding} handler={uptakeHandler}/>
                            </label>
                        </Col>
                        <Col xs={4}>
                            <label htmlFor='residentFunding' className={Style.label}>
                                Resident funding: {assumptions.summary.residentFunding}%
                                <MaterialSlider name='residentFunding' disabled={true} value={assumptions.summary.residentFunding} handler={uptakeHandler}/>
                            </label>
                        </Col>
                    </Row>
                </fieldset>
            </form>
        </div>
    )
}

export default Finance;