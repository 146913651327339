const UpdatePhasing = (data, assumptions) => {
    let timeline = {};
    let profileCost = {};
    let profileCount = {};
    let profilePercent = {};
    let graphData = assumptions.graphData;
    assumptions.graphData.months.forEach((month) => {
        profileCost[month] = 0;
        profileCount[month] = 0;
    });
    timeline['all'] = {cost: profileCost, count: profileCount};
    data.dataTotalsMeasure.forEach(measure => {
        profileCost = {};
        profileCount = {};
        profilePercent = {};
        graphData.months.forEach((month) => {
            let cost = Number(measure.cost) / graphData.projectLength;
            let count = Number(measure.count) / graphData.projectLength;
            profileCost[month] = cost;
            profileCount[month] = count;
            profilePercent[month] = count/Number(measure.count);
            timeline['all'].cost[month] = timeline['all'].cost[month] + profileCost[month];
            timeline['all'].count[month] = timeline['all'].count[month] + profileCount[month];
            timeline[measure.id] = {cost: profileCost, count: profileCount, percentage: profilePercent}
        });
    });

    return timeline;
}

export default UpdatePhasing;
