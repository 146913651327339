const UpdateAssumptions = (currentMeasureSelection, dataNew, assumptionsNew, mapNew) => {
    // Get new measure selections
    let measuresSelected =[];
    let measures = assumptionsNew.measures.map(measure => {
        measure.checked = assumptionsNew.summary.population === "populated" ? 1 : '';
        measure.disabled = currentMeasureSelection ==='wh' ? true : false;
        if(assumptionsNew.summary.population === "populated" | currentMeasureSelection ==='wh') measuresSelected.push(measure.id);
        return measure;
    })
    assumptionsNew.measuresSelected = measuresSelected;
    assumptionsNew.measures = measures;
    assumptionsNew.summary.measuresSelected = measuresSelected;

    // Get new map selections
    let mapSelected = assumptionsNew.summary.population === "populated" ? dataNew.lsoaList : [];
    mapNew.mapSelected = mapSelected;
    assumptionsNew.summary.mapSelected = mapSelected;

    return({
        assumptionsNew: assumptionsNew,
        mapNew: mapNew,
    })
};

export default UpdateAssumptions;