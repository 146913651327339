import React, { useState, createContext } from 'react';

export const MapContext = createContext();
export const MapContextProvider = props => {
    const [selections, setSelections] = useState(
        {
            mapSelected: [],
            hovered: [],
        }
    );
    return(
        <MapContext.Provider value={[selections, setSelections]}>
            {props.children}
        </MapContext.Provider>
    )
}