import React, {useContext, useRef} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {AssumptionsContext} from '../../../../context/AssumptionsContext';
import {DataContext} from '../../../../context/DataContext';
import {MapContext} from '../../../../context/MapContext';
import CalculateTotals from '../../../../logic/CalculateTotals';
import CalculateTotalsMeasures from '../../../../logic/CalculateTotalsMeasures';
import CalculateTableData from '../../../../logic/CalculateTableData';
import UpdateData from '../../../../logic/UpdateData';

const Table = () => {

    const [data, setData] = useContext(DataContext);
    const [assumptions, setAssumptions] = useContext(AssumptionsContext);
    const [map] = useContext(MapContext);
    const inputRef = useRef(null)

    const formatColumnNumber = (params) => {
        const valueFormatted = Number(params.value).toLocaleString(undefined, {maximumFractionDigits:0});
        return `${valueFormatted}`;
    };

    // Define table's base columns
    const columns = [
        { 
            field: 'id', 
            headerName: 'Postcode', 
            width: 150,
        },
        {
            field: 'lsoa',
            headerName: 'LSOA',
            width: 150,
        },
        { 
            field: 'uprn_count', 
            headerName: 'Property count', 
            type: 'number',
            description: 'Count of domestic UPRNs within this postcode',
            width: 180,
            valueFormatter: (params) => {formatColumnNumber(params)}
        },
        { 
            field: 'property_count', 
            headerName: 'Targeted properties', 
            type: 'number',
            description: 'Count of domestic properties targetted by scheme',
            width: 180,
            valueFormatter: (params) => {formatColumnNumber(params)}
        },
        {
            field: 'totalCount',
            headerName: 'Measure count',
            type: 'number',
            description: 'Count of retrofit measures recommended for households within this postcode',
            width: 180,
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value).toLocaleString(undefined, {maximumFractionDigits:0});
                return `${valueFormatted}`;
            }
        },
        {
            field: 'totalCost',
            headerName: 'Measure cost',
            type: 'number',
            description: 'Cost of retrofit measures recommended for households within this postcode',
            width: 180,
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value).toLocaleString(undefined, {maximumFractionDigits:0});
                return `${valueFormatted}`;
            }
        }
    ];

    // Append columns
    function capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    let column = (metric, measure, measureName) => {
        let unit = metric === 'cost' ? '£' : metric === 'energy' ? 'kWh' : 'kgCO2';
        let prefix = metric === 'cost' ? '' : 'Lifetime ';
        let sufix = metric === 'cost' ? '' : ' saving';
        return(
            {
                field: measure + '_' + metric,
                headerName: capitaliseFirstLetter(metric) + ' - ' + measure,
                type: 'number',
                description: prefix + capitaliseFirstLetter(metric) + sufix + ' of ' + measureName + ' measure recommended for households within this postcode (' + unit + ')',
                width: 180,
                valueFormatter: (params) => {
                    const valueFormatted = Number(params.value).toLocaleString(undefined, {maximumFractionDigits:0});
                    return `${valueFormatted}`;
                }
            }
        )
    };

    assumptions.measuresSelected.forEach(measure => {
        let measureName = assumptions.measures
            .filter(x => measure === x.id)
            .map(x => x.text);
        columns.push(column('cost', measure, measureName.toString().toLowerCase()))
        columns.push(column('energy', measure, measureName.toString().toLowerCase()))
        columns.push(column('CO2', measure, measureName.toString().toLowerCase()))
    });

    // Input handlers
    const handleSelection = (e) => {
        let selectedPostcodes = e;

        setAssumptions(prev => {
            prev.summary.selectedPostcodes = selectedPostcodes;
            return({...prev});
        });

        const geoFiltered = Object.values(data.dataPostcode).filter(f => {return(selectedPostcodes.includes(f.postcode) ? f.lsoa : false)});
            
        if(selectedPostcodes.length === 0){
            setData(prev => {
                prev.dataFinal = CalculateTableData(data.dataPostcode, assumptions, CalculateTotalsMeasures);
                prev.dataTotalsMeasure = CalculateTotalsMeasures(data.dataPostcode, assumptions);
                prev.dataTotalsKPI = prev.dataTotals;
                return({...prev});
            });
        } else {
            setData(prev => {
                prev.dataTotalsMeasure = CalculateTotalsMeasures(geoFiltered, assumptions);
                prev.dataFinal = CalculateTableData(geoFiltered, assumptions, CalculateTotalsMeasures);
                prev.dataTotalsKPI = CalculateTotals(geoFiltered, assumptions, assumptions.summary.uptake);
                return({...prev});
            });
        };

        let dataUpdated = UpdateData(data, map, assumptions);

        setData(dataUpdated);
        inputRef.current.click();
    };

    const handleClick = (e) => inputRef.current.click();
    let x = 1;
    
    return (
        <div style={{ height: 660, width: '100%' }} >
            <DataGrid 
                rows={data.dataTable} 
                columns={columns} 
                pageSize={10} 
                checkboxSelection
                selectionModel = {assumptions.summary.selectedPostcodes}
                onSelectionModelChange={e => handleSelection(e)}
                onRowClick={e => handleClick(e)}
                rowsPerPageOptions={[10,25,50]}
                state={{
                    keyboard: {
                        cell: null,
                        columnHeader: null,
                        isMultipleKeyPressed: false,
                    }
                }}
            />
            <div ref={inputRef} onClick={()=> {x = x + 1}} />
        </div>
    )
}

export default Table
