import React, {useContext, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import Style from './Assumptions.module.css';
import {AssumptionsContext} from '../../../../context/AssumptionsContext';
import {MapContext} from '../../../../context/MapContext';
import {DataContext} from '../../../../context/DataContext';
import {apiProvider} from '../../../../data/Api';
import Loading from '../../../../layout/Loading';

import UpdateData from '../../../../logic/UpdateData';
import UpdateAssumptions from '../../../../logic/UpdateAssumptions';
import UpdateSource from '../../../../logic/UpdateSource';

const Assumptions = () => {

    const [assumptions, setAssumptions] = useContext(AssumptionsContext);
    const [map, setMap] = useContext(MapContext);
    const [data, setData] = useContext(DataContext);

    const [loading, setLoading] = useState(false);

    // Input handlers
    const inputHandler = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setAssumptions(prev => {
            prev.summary[name] = value;
            return ({...prev})
        });
    };

    const measureHandler = event =>  {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        // Update assumptions context        
        setAssumptions(selected => {
            let index = selected.measuresSelected.indexOf(name);
            index === -1 ? selected.measuresSelected.push(name) : selected.measuresSelected.splice(index, 1);

            selected.measures.map(measure => {
                if(measure.id === name) measure.checked = value;
                return measure;
            });
            return ({...selected});
        });

        // Update data context
        let dataUpdated = UpdateData(data, map, assumptions);
        setData(dataUpdated);
    };
    
    const gasHandler = event =>  {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        // Update assumptions context        
        setAssumptions(selected => {
            selected.summary[target.dataset.group] = name;
            selected.gasFilters.map(filter => {
                if(filter.id === name & filter.checked) return filter;
                else if(filter.id === name) filter.checked = value;
                else filter.checked = '';
                return filter;
            });
            return ({...selected});
        });
    };

    const epcHandler = event =>  {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        let x = target.dataset.group === 'epc' ? 'epcFilters' : target.dataset.group === 'propertyType' ? 'propertyTypeFilters' : 'tenureTypeFilters';

        // Update assumptions context        
        setAssumptions(selected => {
            let index = selected.summary[target.dataset.group].indexOf(name);
            if(index !== -1 & selected.summary[target.dataset.group].length === 1) return ({...selected});
            index === -1 ? selected.summary[target.dataset.group].push(name) : selected.summary[target.dataset.group].splice(index, 1);
            selected[x].map(filter => {
                if(filter.id === name) filter.checked = value;
                return filter;
            });
            return ({...selected});
        });
    };

    const dataSourceHandler = async event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        setLoading(true);
        
        let assumptionsNew = {...assumptions};
        assumptionsNew.summary[name] = value;

        let dataNew = data;
        if(["measureSelection", "filterSelection"].includes(name)) {
            let dataSource = await UpdateSource(data, assumptionsNew, apiProvider);
            dataNew.dataLsoaFull = dataSource.dataLsoaFull;
            dataNew.dataPostcodeFull = dataSource.dataPostcodeFull;
        };

        // Update assumptions
        let updates = {assumptionsNew: assumptionsNew, mapNew: map};
        if(!["filterSelection"].includes(name)) updates = UpdateAssumptions(assumptions.summary.measureSelection, dataNew, assumptionsNew, map);
        let dataUpdated = UpdateData(dataNew, updates.mapNew, updates.assumptionsNew);
        setData(prev => {
            prev = dataUpdated;
            return({...prev});
        });
        setMap(prev => {
            prev = updates.mapNew;
            return({...prev});
        });
        setAssumptions(prev => {
            prev = updates.assumptionsNew;
            return({...prev});
        });

        setLoading(false);
    };

    const updateFilters = event => {
        event.preventDefault();
        let x = {target: {name: 'filterSelection', value: ''}}
        if(assumptions.summary.gas !== 'gasBoth' | assumptions.summary.epc.length !== 7 | assumptions.summary.propertyType.length !== 5 | assumptions.summary.tenureType.length !== 4) x.target.value = 'custom'
        else x.target.value = 'standard';

        dataSourceHandler(x);
    };

    // Checkbox components - to be moved to layout
    const Checkbox = (props) => {
        return (
            <div className={Style.inputContainer}>
                <label>
                    <input 
                        type="checkbox" 
                        className={Style.radioInput} 
                        name={props.name}
                        checked={props.checked}
                        onChange={props.handler}
                        disabled={props.disabled}
                        data-group={props.group}
                    />
                    <span>{props.label}</span>
                </label>
            </div>
        )
    ;}

    const CheckboxGroup = (props) => {
        return(
            <fieldset id={props.group}>
                {props.measureGroup.map((measure, index) => (
                    <Checkbox name={measure.id} key={index} checked={measure.checked} label={measure.text} disabled={measure.disabled} handler={props.handler} group={props.group}/>
                ))}
            </fieldset>
        )
    };

const loadingContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

    return(
        <div className={Style.assumptionsContainer}>
            {
                loading 
                ? <div>
                    <div>Please wait - this could take a minute or two</div>
                    <div style={loadingContainer}>
                        <Loading/>
                    </div>
                </div>
                : <form autoComplete="off">
                    <fieldset>
                        <legend><h3>Step 1 - Enter project details</h3></legend>
                        <Row className={Style.inputRow}>
                            <Col sm={4}>
                                <label htmlFor='name' className={Style.label} data-tip data-for="nameTip">
                                    Project name
                                    <input
                                        type="text"
                                        name='name'
                                        value={data.user.loadedProject !== '' ? data.user.loadedProject : assumptions.summary.name}
                                        className={Style.input}
                                        onChange={inputHandler}
                                        disabled={data.user.loadedProject !== '' ? true : false}
                                    />
                                </label>
                                <ReactTooltip id="nameTip" place="top" effect="solid">Give the project a name</ReactTooltip>
                            </Col>
                            <Col sm={4}>
                                <label htmlFor='owner' className={Style.label} data-tip data-for="ownerTip">
                                    Lead organisation
                                    <input
                                        type="text"
                                        name='owner'
                                        value={assumptions.summary.owner}
                                        className={Style.input}
                                        onChange={inputHandler}
                                    />
                                </label>
                                <ReactTooltip id="ownerTip" place="top" effect="solid">Enter the name of the project owner e.g. the Local Authority name</ReactTooltip>
                            </Col>
                        </Row>
                        <Row className={Style.inputRow}>
                            <Col sm={4}>
                                <label htmlFor="population" className={Style.label} data-tip data-for="populationTip">
                                    Filter selection
                                    <select 
                                        name="population"
                                        value={assumptions.summary.population}
                                        onChange={dataSourceHandler}
                                        data-group='population'
                                    >
                                        <option value="empty">Custom selection</option>
                                        <option value="populated">Select all</option>
                                    </select>
                                </label>
                                <ReactTooltip id="populationTip" place="top" effect="solid">Choose to either build your project top down from all measures and geographies selected ('Select all'), or bottom up ('Custom selection')</ReactTooltip>
                            </Col>
                            <Col sm={4}>
                                <label htmlFor="measureSelection" className={Style.label}>
                                    Measure selection
                                    <select 
                                        name="measureSelection"
                                        value={assumptions.summary.measureSelection}
                                        onChange={dataSourceHandler}
                                        data-group='measureSelection'
                                    >
                                        <option value="custom">Custom</option>
                                        <option value="wh">Whole-house to C</option>
                                    </select>
                                </label>
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset className={Style.border}>
                        <legend><h3>Step 2 - Select targeted properties</h3></legend>
                        <Row className={Style.inputRow}>
                            <Col sm={3} className={Style.borderRight}>
                                <label htmlFor='gas'><h4>Mains gas</h4></label>
                                <CheckboxGroup measureGroup={assumptions.gasFilters.filter(filter => filter.group === 'gas')} group='gas' handler={gasHandler}/>
                            </Col>
                            <Col sm={3} className={Style.borderRight}>
                                <label htmlFor='epc'><h4>EPC rating</h4></label>
                                <CheckboxGroup measureGroup={assumptions.epcFilters.filter(filter => filter.group === 'epc')} group='epc' handler={epcHandler}/>
                            </Col>
                            <Col sm={3} className={Style.borderRight}>
                                <label htmlFor='epc'><h4>Property type</h4></label>
                                <CheckboxGroup measureGroup={assumptions.propertyTypeFilters.filter(filter => filter.group === 'propertyType')} group='propertyType' handler={epcHandler}/>
                            </Col>
                            <Col sm={3} >
                                <label htmlFor='epc'><h4>Tenure type</h4></label>
                                <CheckboxGroup measureGroup={assumptions.tenureTypeFilters.filter(filter => filter.group === 'tenureType')} group='tenureType' handler={epcHandler}/>
                            </Col>
                        </Row>
                        <div>Click button to apply property filters.</div>
                        <div style={{paddingBottom: '0.5rem'}}>Note: a large amount of data processing is required to filter at property level - applying these filters could take up to a minute.</div>
                        <button className={Style.central} onClick={updateFilters}>Apply filters</button>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Step 3 - Select targeted retrofit measures</h3></legend>
                        <Row className={Style.inputRow}>
                            <Col sm={3} className={Style.borderRight}>
                                <label htmlFor='wall'><h4>Wall</h4></label>
                                <CheckboxGroup measureGroup={assumptions.measures.filter(measure => measure.group === 'wall')} group='wall' handler={measureHandler}/>
                            </Col>
                            <Col sm={3} className={Style.borderRight}>
                                <label htmlFor='roof'><h4>Roof</h4></label>
                                <CheckboxGroup measureGroup={assumptions.measures.filter(measure => measure.group === 'roof')} group='roof' handler={measureHandler}/>
                            </Col>
                            <Col sm={3} className={Style.borderRight}>
                                <label htmlFor='heating'><h4>Heating</h4></label>
                                <CheckboxGroup measureGroup={assumptions.measures.filter(measure => measure.group === 'heating')} group='heating' handler={measureHandler}/>
                            </Col>
                            <Col sm={3}>
                                <label htmlFor='other'><h4>Other</h4></label>
                                <CheckboxGroup measureGroup={assumptions.measures.filter(measure => measure.group === 'other')} group='other' handler={measureHandler}/>
                            </Col>
                        </Row>
                    </fieldset>
                </form>
            }
        </div>
    )
}

export default Assumptions;