import React, { useState, createContext } from 'react';

export const SliderContext = createContext();
export const SliderContextProvider = props => {
    const [slider, setSlider] = useState({ x: 100 });
    return(
        <SliderContext.Provider value={[slider, setSlider]}>
            {props.children}
        </SliderContext.Provider>
    )
}