import React, {useContext} from 'react';
import { Row, Col } from 'react-bootstrap';
import {DataGrid} from '@material-ui/data-grid';
import Style from './Table.module.css';
import {DataContext} from '../../../../context/DataContext';
import {AssumptionsContext} from '../../../../context/AssumptionsContext';

function TableSummary() {
    const [data] = useContext(DataContext);
    const [assumptions] = useContext(AssumptionsContext);

    // Data
    let rows = data.dataTotalsMeasure;
    let measures = rows.map(row => row.id);
    let dataTotals = data.dataTotalsKPI;
    if(assumptions.summary.managementCost !== 0 && !measures.includes('Management costs')) rows.push({
        id: 'Management costs', 
        count: 1,
        cost: dataTotals.managementCost,
        averageCost: dataTotals.managementCost,
    });
    if(assumptions.summary.contingencyCost !== 0 && !measures.includes('Contingency costs')) rows.push({
        id: 'Contingency costs', 
        count: 1,
        cost: dataTotals.contingencyCost,
        averageCost: dataTotals.contingencyCost,
    });
    if(assumptions.summary.auxiliaryCost !== 0 && !measures.includes('Auxiliary costs')) rows.push({
        id: 'Auxiliary costs', 
        count: 1,
        cost: Math.round(assumptions.summary.auxiliaryCost),
        averageCost: Math.round(assumptions.summary.auxiliaryCost)
    });

    // Define table
    const columns = [
        { 
            field: 'id', 
            headerName: 'Project cost', 
            width: 180,
        },
        {
            field: 'count',
            headerName: 'Count',
            type: 'number',
            description: 'Total count of measures',
            width: 150,
        },
        { 
            field: 'cost', 
            headerName: 'Cost (£)', 
            type: 'number',
            description: 'Total cost of measures',
            width: 180,
        },
        { 
            field: 'energy', 
            headerName: 'Energy saving', 
            type: 'number',
            description: 'Total energy saving from measures (kWh)',
            width: 180,
        },
        { 
            field: 'carbon', 
            headerName: 'CO2 saving', 
            type: 'number',
            description: 'Total carbon saving from measures (kgCO2)',
            width: 180,
        }
    ];

    const Table = (props) => {
        let {data} = props;
        let empty = data.length === 0 ? 52 : 0;
        let tableHeight = 110 + empty + (data.length * 52); // Autoheight doesn't work; manually set height of container
        let tableWidth = 180 * 4 + 165
        return(
            <div style={{ height: tableHeight, width: tableWidth, marginTop: '1rem' }}>
                <DataGrid 
                    hideFooterPagination              
                    rowHeight={52}
                    rows={data} 
                    columns={columns}
                    state={{
                        keyboard: {
                          cell: null,
                          columnHeader: null,
                          isMultipleKeyPressed: false,
                        }
                    }}
                />
            </div>
        )
    };

    return (
        <div>
            <Row>
                <Col md={12} className={Style.flexParent}>
                    <Table data={rows} className={Style.table}/>
                </Col>
            </Row>
        </div>
    )
};

export default TableSummary;
