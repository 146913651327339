import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Link, useLocation} from 'react-router-dom';
import LogoWhite from '../images/LogoTransparent.png';
import LogoWhite2x from '../images/LogoTransparent.png';
import Style from './Layout.module.css';
import {DataContext} from '../context/DataContext';
import {AssumptionsContext} from '../context/AssumptionsContext';

import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faCoins, faTools, faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip";

import LogoMark from '../images/LogomarkOrange.png';
import Logo from '../images/Thermly-Orange.svg';

const HeaderView = () => {
    const location = useLocation();
    return <span>Path : {location.pathname}</span>
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
});

const Navbar = ({icon, title}) => {
    const { isAuthenticated } = useAuth0();
    const { logout } = useAuth0();
    const [data] = useContext(DataContext);
    /* eslint-disable no-unused-vars */
    const [assumptions] = useContext(AssumptionsContext);
    let dataTotals = data.dataTotalsKPI;
    let page = HeaderView();


    return (
        <nav className="navbar bg-primary">
            {/* <div className='navbarLogoContainer'>
                <img src={LogoMark} srcSet={`${LogoMark} 2x`} alt='C-path logo' style={{height: '40px'}}/>
                <img src={Logo} srcSet={`${Logo} 2x`} alt='C-path logo' style={{height: '40px', paddingTop: '5px'}}/>
            </div> */}
                <img src={LogoWhite} srcSet={`${LogoWhite} 2x`} alt='Thermly logo' style={{height: '70px'}}/>
            <ul>
                {
                    page.props.children[1] === "/Plan"
                    ? <div className={Style.kpis}>
                        <li><FontAwesomeIcon icon={faTools} data-tip data-for="toolsTip" className={Style.navIcon} size="lg"/>{dataTotals.totalCount.toLocaleString()}</li>
                        <ReactTooltip id="toolsTip" place="top" effect="solid" html={true}>{'Number of measures to be installed'}</ReactTooltip>
                        <li><FontAwesomeIcon icon={faCoins} data-tip data-for="costTip" className={Style.navIcon} size="lg"/>{formatter.format(dataTotals.totalCostProject)}</li>
                        <ReactTooltip id="costTip" place="top" effect="solid" html={true}>{'Total cost of project'}</ReactTooltip>
                        <li><FontAwesomeIcon icon={faLeaf} data-tip data-for="savingTip" className={Style.navIcon} size="lg"/>{dataTotals.totalCarbon.toLocaleString()}</li>
                        <ReactTooltip id="savingTip" place="top" effect="solid" html={true}>{'Total carbon reduction of project (kgCO<sub>2</sub>)'}</ReactTooltip>
                        {/* <li><Link to='/Info'><FontAwesomeIcon icon={faInfoCircle} data-tip data-for="infoTip"/></Link></li>
                        <ReactTooltip id="infoTip" place="top" effect="solid" html={true}>{'How it works'}</ReactTooltip> */}
                    </div>
                    : null
                }
                <li><Link to='/Home'><FontAwesomeIcon icon={faHome} data-tip data-for="homeTip" size="lg"/></Link></li>
                <ReactTooltip id="homeTip" place="top" effect="solid" html={true}>{'Home'}</ReactTooltip>
                <li>
                    {isAuthenticated ? 
                        <Link
                            to="/#"
                            onClick={() =>
                                logout({
                                returnTo: window.location.origin,
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} data-tip data-for="logOutTip" size="lg"/>
                            <ReactTooltip id="logOutTip" place="top" effect="solid" html={true}>{'Log out'}</ReactTooltip>  
                        </Link> : <div />
                    }
                </li>                     
            </ul>
        </nav>
    );
};

Navbar.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

Navbar.defaultProps ={
  title: 'Github Finder',
  icon: 'fab fa-github'
};

export default Navbar
