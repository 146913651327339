import React, {useContext} from 'react'
import { Row, Col } from 'react-bootstrap';
import Style from './KPI.module.css';
import {DataContext} from '../../../../context/DataContext'
import ReactTooltip from "react-tooltip";
import formatNumber from '../../../../logic/FormatNumber';

const KPIsummary = () => {
    const [data] = useContext(DataContext);
    let dataTotals = data.dataTotalsKPI;

    // Move this logic to a custom hook; can be called from different components that need to set totals in context for display in components
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
    });
    
    // Filter data
    let totalCost = dataTotals.totalCost;
    let totalCostProject = dataTotals.totalCostProject;
    let totalCostFinal = dataTotals.totalCostFinal;

    return (
        <div>
            <Row className="justify-content-md-center">
                <Col md={2} className={`all-center`} data-tip data-for="kpiFinanceCost">
                    <div className={Style.kpiValue}>{formatNumber(totalCost, 'currency')}</div>
                    <ReactTooltip id="kpiFinanceCost" place="top" effect="solid" html={true}>{'Total cost of all measures forecast to be implemented, without management and contingency fees.'}</ReactTooltip>  
                </Col>
                <Col md={2} className={`all-center`} data-tip data-for="kpiFinanceProject">
                    <div className={Style.kpiValue}>{formatNumber(totalCostProject, 'currency')}</div>
                    <ReactTooltip id="kpiFinanceProject" place="top" effect="solid" html={true}>{'Total cost of the project, including non-measure costs.'}</ReactTooltip>  
                </Col>
                <Col md={2} className={`all-center`} data-tip data-for="kpiFinanceFinal">
                    <div className={Style.kpiValue}>{formatNumber(totalCostFinal, 'currency')}</div>
                    <ReactTooltip id="kpiFinanceFinal" place="top" effect="solid" html={true}>{'Total cost of the project, including non-measure costs, to lead organisation after assumed third party and / or householder contributions.'}</ReactTooltip>  
                </Col>
            </Row>
            <Row>
                <Col md={2} className={`all-center`}>
                    <div>Measure cost</div>
                </Col>
                <Col md={2} className={`all-center`}>
                    <div>Project cost</div>
                </Col>
                <Col md={2} className={`all-center`}>
                    <div >Cost to lead org.</div>
                </Col>
            </Row>
        </div>
    )
}

export default KPIsummary
