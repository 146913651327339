
import L from "leaflet";
import { useEffect } from "react";


function Title({ map, focus }) {
    useEffect(() => {
      if (map) {
        const legend = L.control({ position: "topleft" });
  
        legend.onAdd = () => {
            const div = L.DomUtil.create("div", "info legend");
            div.innerHTML = '<div style="background: #fff; padding: 0.5rem; border-radius: 10px"><h3>Step 4 - Select LSOAs for inclusion in the project</h3></div>';
            return div;
        };
  
        legend.addTo(map);
      }
    }, [map]);
    return null;
  }

export default Title
