import React, {useContext} from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Style from './KPI.module.css';
import {DataContext} from '../../../../context/DataContext';
import ReactTooltip from "react-tooltip";
import formatNumber from '../../../../logic/FormatNumber';

const KPIsummary = () => {
    const [data] = useContext(DataContext);
    let dataTotals = data.dataTotalsKPI;
    
    // Filter data
    let totalCount = dataTotals.totalCount;
    let totalCost = dataTotals.totalCost;
    let totalCostFinal = dataTotals.totalCostFinal;
    let totalCarbon = dataTotals.totalCarbon;
    let totalEnergy = dataTotals.totalEnergy;
    let totalFuelPoverty = dataTotals.totalFuelPoverty;

    let employmentImpact = data.dataTotalsMeasure.reduce((impact, measure) => {
        data.employmentImpact
            .filter(x => measure.measure === x.measure)
            .map(x => impact += Math.round((Number(x.fte_days) * measure.count) / 228));
        return(impact);
    }, 0);

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={2} className={`all-center`} data-tip data-for="kpiSummaryMeasure">
                            <div className={Style.kpiValue}>{formatNumber(totalCount)}</div>
                            <ReactTooltip id="kpiSummaryMeasure" place="top" effect="solid" html={true}>{'Total number of measures forecast to be implemented.  Not necessarily number of households if some households have multiple measures.'}</ReactTooltip>  
                        </Col>
                        <Col md={2} className={`all-center`} data-tip data-for="kpiSummaryCost">
                            <div className={Style.kpiValue}>{formatNumber(totalCost, 'currency')}</div>
                            <ReactTooltip id="kpiSummaryCost" place="top" effect="solid" html={true}>{'Total cost of all measures forecast to be implemented, without management and contingency fees.'}</ReactTooltip>  
                        </Col>
                        <Col md={2} className={`all-center`} data-tip data-for="kpiSummaryCostProject">
                            <div className={Style.kpiValue}>{formatNumber(totalCostFinal, 'currency')}</div>
                            <ReactTooltip id="kpiSummaryCostProject" place="top" effect="solid" html={true}>{'Total cost of the project, including non-measure costs, to lead organisation after assumed third party and / or householder contributions.'}</ReactTooltip>  
                        </Col>
                        <Col md={2} className={`all-center`} data-tip data-for="kpiSummaryCarbon">
                            <div className={Style.kpiValue}>{formatNumber(totalCarbon)}</div>
                            <ReactTooltip id="kpiSummaryCarbon" place="top" effect="solid" html={true}>{'Total lifetime carbon saving of all measures forecast to be implemented.'}</ReactTooltip>  
                        </Col>
                        <Col md={2} className={`all-center`} data-tip data-for="kpiSummaryFTE">
                            <div className={Style.kpiValue}>{employmentImpact.toLocaleString()}</div>
                            <ReactTooltip id="kpiSummaryFTE" place="top" effect="solid" html={true}>{'Estimated FTE employment impact of implementing all forecast measures.'}</ReactTooltip>  
                        </Col>
                        <Col md={2} className={`all-center`} data-tip data-for="kpiSummaryFP">
                            <div className={Style.kpiValue}>{totalFuelPoverty.toLocaleString()}</div>
                            <ReactTooltip id="kpiSummaryFP" place="top" effect="solid" html={true}>{'Number of households estimated to be impacted by fuel poverty where measures would be installed, based on LSOA-level averages.'}</ReactTooltip>  
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} className={`all-center`}>
                            Measure count
                        </Col>
                        <Col md={2} className={`all-center`}>
                            Measure Cost
                        </Col>
                        <Col md={2} className={`all-center`}>
                            Cost to lead org.
                        </Col>
                        <Col md={2} className={`all-center`}>
                            <span>CO<sub>2</sub> saving (kgCO<sub>2</sub>)</span>
                        </Col>
                        <Col md={2} className={`all-center`}>
                            Employment impact (FTE years)
                        </Col>
                        <Col md={2} className={`all-center`}>
                            Fuel poverty impact (properties)
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <Row>
                        <Col md={4} className={`all-center`} data-tip data-for="kpiSummaryFP">
                            <div className={Style.kpiValue}>{formatNumber(totalCost / totalCount, 'currency')}</div>
                            <ReactTooltip id="kpiSummaryFP" place="top" effect="solid" html={true}>{'Total cost per measure implemented.'}</ReactTooltip>  
                        </Col>
                        <Col md={4} className={`all-center`} data-tip data-for="kpiSummaryCarbonMeasure">
                            <div className={Style.kpiValue}>{formatNumber((totalCarbon / totalCount))}</div>
                            <ReactTooltip id="kpiSummaryCarbonMeasure" place="top" effect="solid" html={true}>{'Total lifetime carbon saved per measure implemented.'}</ReactTooltip>  
                        </Col>
                        <Col md={4} className={`all-center`} data-tip data-for="kpiSummaryEnergyMeasure">
                            <div className={Style.kpiValue}>{formatNumber((totalEnergy / totalCount))}</div>
                            <ReactTooltip id="kpiSummaryEnergyMeasure" place="top" effect="solid" html={true}>{'Total lifetime energy saved per measure implemented.'}</ReactTooltip>  
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className={`all-center`}>
                            Cost / measure
                        </Col>
                        <Col md={4} className={`all-center`}>
                            <span>CO<sub>2</sub> saving / measure</span>
                        </Col>
                        <Col md={4} className={`all-center`}>
                            kWh saving / measure
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default KPIsummary
