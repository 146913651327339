import React, { useContext, useState, Fragment } from 'react'
import { MapContainer, GeoJSON, TileLayer, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import {MapContext} from '../../../../context/MapContext';
import {AssumptionsContext} from '../../../../context/AssumptionsContext';

import Legend from './labels/Legend';
import Title from './labels/Title';

import {DataContext} from '../../../../context/DataContext';
import UpdateMapColour from '../../../../logic/UpdateMapColour';
import UpdateData from '../../../../logic/UpdateData';

const MyMap = () => {

    const [map, setMap] = useContext(MapContext);
    const [assumptions] = useContext(AssumptionsContext);
    const [data, setData] = useContext(DataContext);

    const [mapInstance, setMapInstance] = useState(null)

    const mapStyle = {
        height: '40rem',
        width: '100%',
        borderRadius: '10px'
    }

    const tooltip = (lsoa, layer) => {
        const lsoaName = lsoa.properties.LSOA11NM;
        layer.bindTooltip(lsoaName);

        layer.on({
            mouseover: () => {
                if(assumptions.summary.summarySelection === 'lsoa') setMap({mapSelected: map.mapSelected, hovered: lsoaName});
            },
            mouseout: () => {
                if(assumptions.summary.summarySelection === 'lsoa') setMap({mapSelected: map.mapSelected, hovered: []});
            },
            click: () => {
                // Update map selection context - move to assumptions context
                let temp = map.mapSelected;
                if(temp.includes(lsoaName)) {
                    let lsoaIndex = temp.indexOf(lsoaName);
                    temp.splice(lsoaIndex, 1);
                } else {
                    temp.push(lsoaName);
                }
                setMap(prev => {
                    let updated = {mapSelected: temp, hovered: prev.hovered};
                    return({...updated})
                });

                // Update data context
                let dataUpdated = UpdateData(data, map, assumptions);
                setData(prev => {
                    prev = dataUpdated;
                    return({...prev});
                });
            }
        })
    };

    function renderGeoJson(geojson, measuresSelected, regionsSelected) {
        if(Object.keys(geojson.geoJson).length === 0) return <GeoJSON />
        let geoJsonUpdated = UpdateMapColour(geojson, measuresSelected, assumptions.summary.focus);

        return Object.values(geoJsonUpdated.features).map((lsoa, i) => {
            let selected = regionsSelected.includes(lsoa.properties.LSOA11NM) ? 1 : 0;
            let style = () => {
                return {
                    fillColor: lsoa.properties.color,
                    fillOpacity: selected === 1 ? 0.8 : 0.3,
                    color: 'black',
                    weight: selected === 1 ? 2 : 0.5,
                };
            };
        
            return (
                <GeoJSON key={lsoa.properties.LSOA11NM + lsoa.properties.color + selected} data={lsoa} style={style} onEachFeature={tooltip} />
            );
        });
    };

    // function ChangeView ({ center, zoom }) {
    //     const map = useMap();
    //     map.setView(center);
    //     return null;
    // };

    return(
        <Fragment>
            {
                data.coords.length === 0 
                ? <div style={mapStyle}/> 
                : <MapContainer style={mapStyle} zoom={10} zoomControl={false} center={ data.coords } whenCreated={setMapInstance} key={JSON.stringify(data.coords)}>
                    {/* <ChangeView center={data.coords} /> */}
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    { renderGeoJson(data, assumptions.measuresSelected, map.mapSelected) }
                    <ZoomControl position="topright" />
                    <Title map={mapInstance} focus={assumptions.summary.focus}/>
                    <Legend map={mapInstance} focus={assumptions.summary.focus}/>
                </MapContainer>
            }
        </Fragment>
    )
}

export default MyMap;