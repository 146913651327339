import React from "react";
import loadingImg from './spinner.svg';

const spinner = {
  height: "15rem",
  width: "15rem",
  zIndex: "99998"
};
const Loading = () => (
  <img src={loadingImg} alt="Loading..." style={spinner}/>
);

export default Loading;
