import React, {useState, useEffect, useContext, useRef} from 'react';
import { Row, Col } from 'react-bootstrap';
import Style from './Plan.module.css';
import HelpIcon from '@material-ui/icons/Help';
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import { Prompt } from 'react-router'

import Assumptions from './components/assumptions/Assumptions';
import Map from './components/map/Map';
import Summary from './components/map/summary/Summary';
import TablePostcode from './components/table/TablePostcode';
import TableSummary from './components/table/TableSummary';
import Finance from './components/finance/Finance';
import Phasing from './components/phasing/Phasing';
// import Timeline from './components/timeline/Timeline';
import KPIsummary from './components/kpi/KPIsummary';
import KPIfinance from './components/kpi/KPIfinance';

import Accordion from '../../layout/Accordion';
import Modal from '../../layout/modal/Modal';
import SaveModal from '../../layout/modal/SaveModal';
import Loading from '../../layout/Loading';

import {apiProvider} from '../../data/Api';

import {DataContext} from '../../context/DataContext';
import {MapContext} from '../../context/MapContext';
import {AssumptionsContext} from '../../context/AssumptionsContext';

import UpdateData from '../../logic/UpdateData';
import UpdateSource from '../../logic/UpdateSource';

const Plan = () => {
    let [data, setData] = useContext(DataContext);
    let [map] = useContext(MapContext);
    let [assumptions] = useContext(AssumptionsContext);

    let [postcodeData, setPostcodeData] = useState([]);
    let [measureData, setMeasureData] = useState([]);
    let [modalShow, setModalShow] = useState(false);

    let csvPostcodeLink = useRef();
    let csvSummaryLink = useRef();

    // Helper functions
    const formatNumeric = val => {
        if(typeof(val) !== 'number') {
            if(isNaN(parseFloat(val))) return val;
            val = parseFloat(val);
        };
        return parseFloat(val).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    // Load data
    useEffect(() => {
        let getData = async () =>  {
            try {
                const accessToken = data.user.accessToken;
                const params = data.user.userType + '/' + data.user.userArea;

                // data.user.userType + '/' + 'Kent'
                const lsoaFuelPoverty = await apiProvider.getSingle("geo_lsoa/fuel_poverty", params, accessToken);
                const geojsonData = await apiProvider.getSingle("geo_lsoa", params, accessToken);
                const centroidsData = await apiProvider.getSingle("geo_lsoa/centroids", params, accessToken);

                let lsoaList = [];
                Object.values(geojsonData.features).map(lsoa => lsoaList.push(lsoa.properties.LSOA11NM));

                let dataNew = data;
                dataNew.lsoaFuelPoverty = lsoaFuelPoverty;
                dataNew.geoJson = geojsonData;
                dataNew.coords = centroidsData;
                dataNew.lsoaList = lsoaList;
                let dataSource = await UpdateSource(dataNew, assumptions, apiProvider);
                let dataLsoaStatic = await apiProvider.getSingle('lsoa/epc_count', params, data.user.accessToken);
                dataNew.dataLsoaFull = dataSource.dataLsoaFull;
                dataNew.dataLsoaStatic = dataLsoaStatic;
                dataNew.dataPostcodeFull = dataSource.dataPostcodeFull;

                // Update context
                setData((prev) => {
                    prev = dataNew;
                    let prevUpdated = UpdateData(prev, map, assumptions);
                    return({...prevUpdated});
                });
            } catch (e) {
                console.log(e.message);
            }
        };

        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Input handlers
   const openModal = (e) => {
        e.preventDefault();
        setModalShow(true);
    };
    const closeModal = (e) => {
        e.preventDefault();
        setModalShow(false);
    };

    // download handlers
    const handlePostcodeMeasures = async () => {
        let rows = [...data.dataFinal];

        rows.map(row => {
            Object.keys(row).forEach(key => row[key] = formatNumeric(row[key]));
            delete row.measures;
            delete row.id;
            delete row.fuel_poverty_ranking;
            delete row.percent_fuel_poverty;
            return row;
        })

        await setPostcodeData(rows);
        csvPostcodeLink.current.link.click();
    }

    const handleDownloadMeasures = async () => {
        let rows = data.dataTotalsMeasure;
        let dataTotals = data.dataTotalsKPI;
        let measures = rows.map(row => row.id);
        if(assumptions.summary.managementCost !== 0 && !measures.includes('Management costs')) rows.push({
            id: 'Management costs', 
            count: 1,
            cost: Math.round(dataTotals.managementCost),
            averageCost: Math.round(dataTotals.managementCost)
        });
        if(assumptions.summary.contingencyCost !== 0 && !measures.includes('Contingency costs')) rows.push({
            id: 'Contingency costs', 
            count: 1,
            cost: Math.round(dataTotals.contingencyCost),
            averageCost: Math.round(dataTotals.contingencyCost)
        });
        if(assumptions.summary.auxiliaryCost !== 0 && !measures.includes('Auxiliary costs')) rows.push({
            id: 'Auxiliary costs', 
            count: 1,
            cost: Math.round(assumptions.summary.auxiliaryCost),
            averageCost: Math.round(assumptions.summary.auxiliaryCost)
        });
        await setMeasureData(rows);
        csvSummaryLink.current.link.click();
    }

    // Content
    return(
        <div>
            {
                data.coords.length === 0
                ? <div className='loadingContainer'>
                    <Loading/>
                </div>
                : <div>
                    <div className={Style.header}>
                        <h1 className={`${Style.title} dark-color`}>
                            Project details
                            <HelpIcon data-tip data-for="assumptionsTip" className={`${Style.rightAlign} secondary-color`}/>
                            <ReactTooltip id="assumptionsTip" place="bottom" effect="solid" html={true}>{'Complete steps 1 - 3 sequentially to enter general project assumptions and priority property types and measures to be implemented.'}</ReactTooltip>
                        </h1>
                    </div>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <Accordion component={<Assumptions/>} title='Project assumptions - summary'/>
                        </Col>
                    </Row>
                    <div className={Style.header}>
                        <h1 className={`${Style.title} dark-color`}>
                            Project geography
                            <HelpIcon data-tip data-for="geographyTip" className={`${Style.rightAlign} secondary-color`}/>
                            <ReactTooltip id="geographyTip" place="top" effect="solid" html={true}>{'View the opportunity for retrofit schemes, based on the selected focus - and use the map to select or de-select individual LSOAs for inclusion in the project.'}</ReactTooltip>
                        </h1>
                    </div>
                    <Row className={Style.row}>
                        <Col md={5} className={Style.summaryPane}>
                            <Summary/>
                        </Col>
                        <Col md={7} className={Style.mapPane}>
                            <Map/>
                        </Col>
                    </Row>
                    <div className={Style.header}>
                        <h1 className={`${Style.title} dark-color`}>
                            Project breakdown
                            <HelpIcon data-tip data-for="breakdownTip" className={`${Style.rightAlign} secondary-color`}/>
                            <ReactTooltip id="breakdownTip" place="top" effect="solid" html={true}>{'View retrofit opportunity at postcode level, with measure-by-measure impact analysis.'}</ReactTooltip>
                        </h1>
                        <h3>Step 5 - Select targeted postcodes (all selected by default)</h3>
                    </div>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <TablePostcode/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <button className={Style.button} onClick={handlePostcodeMeasures} data-tip data-for='downloadPostcodesTip'>Download data</button>
                            <CSVLink data={postcodeData} filename={"c-path_postcode_data_download.csv"} className={Style.hidden} target='_blank' ref={csvPostcodeLink}/>
                            <ReactTooltip id="downloadPostcodesTip" place="top" effect="solid" html={true}>{'Download a CSV containing project data, broken down by postcode.'}</ReactTooltip>
                        </Col>
                    </Row>
                    <div className={Style.header}>
                        <h1 className={`${Style.title} dark-color`}>
                            Project uptake & finance
                            <HelpIcon data-tip data-for="financeTip" className={`${Style.rightAlign} secondary-color`}/>
                            <ReactTooltip id="financeTip" place="top" effect="solid" html={true}>{'Define the finance sources for the project, and view a measure-by-measure summary of its makeup.'}</ReactTooltip>
                        </h1>
                    </div>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <Accordion component={<Finance/>} title='Project assumptions - finance'/>
                        </Col>
                    </Row>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <TableSummary/>
                        </Col>
                    </Row>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <KPIfinance/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <button className={Style.button} onClick={handleDownloadMeasures} data-tip data-for='downloadMeasuresTip'>Download data</button>
                            <CSVLink data={measureData} filename={"c-path_summary_data_download.csv"} className={Style.hidden} target='_blank' ref={csvSummaryLink}/>
                            <ReactTooltip id="downloadMeasuresTip" place="top" effect="solid" html={true}>{'Download a CSV containing project data, broken down by project cost.'}</ReactTooltip>
                        </Col>
                    </Row>
                    {/* <div className={Style.header}>
                        <h1 className={`${Style.title} dark-color`}>
                            Project timeline
                            <HelpIcon data-tip data-for="timelineTip" className={`${Style.rightAlign} secondary-color`}/>
                            <ReactTooltip id="timelineTip" place="top" effect="solid" html={true}>{'Define a period over which the project will be completed, and view key milestones over a timeline.'}</ReactTooltip>
                        </h1>
                    </div>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <Accordion component={<Phasing/>} title='Project assumptions - phasing'/>
                        </Col>
                    </Row>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <Timeline/>
                        </Col>
                    </Row> */}
                    <div className={Style.header}>
                        <h1 className={`${Style.title} dark-color`}>
                            Project summary
                            <HelpIcon data-tip data-for="kpiTip" className={`${Style.rightAlign} secondary-color`}/>
                            <ReactTooltip id="kpiTip" place="top" effect="solid" html={true}>{'View the final, summary-level KPIs for the defined project.'}</ReactTooltip>
                        </h1>
                    </div>
                    <Row className={Style.row}>
                        <Col md={12}>
                            <KPIsummary/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <button className={Style.button} onClick={openModal} >Save</button>
                            <Modal type="button" show={modalShow} closeModal={closeModal} title='Save your work.'><SaveModal/></Modal>
                        </Col>
                    </Row>
                </div>
            }
            <Prompt
                message='Unsaved changes will be lost, are you sure you want to leave?'
            />
        </div>
    )
};

export default Plan;