import React from 'react';
import Style from './Login.module.css';
import Banner from '../../images/cpath_banner.svg';
import AuthenticationButton from '../../layout/login/AuthenticationButton';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const Login = () => {
    const { isAuthenticated } = useAuth0();
    const history = useHistory();
    if(isAuthenticated) history.push("/Home");

    return (
        <div className={`${Style.homeBackground}`}>
            <div className={`${Style.homeContent} container`}>
                <Row>
                    <Col md={6} className={`${Style.homeContentLeft}`}>
                        <div className="all-center">
                            <img src={Banner} alt="Thermly landing banner" className={Style.homeBanner}/>
                        </div>
                        <h2 className={`fw-bold nott font-primary`} style={{fontSize: "40px", lineHeight: "1.15", letterSpacing: "-1px", color: "#217CA3", textAlign: "center"}}>Empowering better decisions through enhanced data and intelligence</h2>
                    </Col>
                    <Col md={6} className={`${Style.containerCentral}`}>
                        <AuthenticationButton />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Login
