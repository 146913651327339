import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Style from './Layout.module.css';

const HeaderView = () => {
    const location = useLocation();
    return <span>Path : {location.pathname}</span>
};

const handleEmail = () => {
    window.open('mailto:info@thermly.co.uk?subject=Enquiry');
};

const Footer = () => {
    let page = HeaderView();

    return (
        <Fragment>
            {
                page.props.children[1] === "/Plan"
                ? <div className={Style.footer}>
                    <Row>
                        <Col sm={4} className={Style.borderLeft}>
                            <div className={Style.footerTitle}>Location</div>
                            <Row>
                                <Col md={6}>
                                    <div style={{textDecoration: "underline"}}>Southern office</div>
                                    <div>West Hill</div>
                                    <div>61 London Road</div>
                                    <div>Maidstone</div>
                                    <div>ME16 8TX</div>
                                </Col>
                                <Col md={6}>
                                    <div style={{textDecoration: "underline"}}>Northern office</div>
                                    <div>First Floor</div>
                                    <div>40 King Street</div>
                                    <div>Manchester</div>
                                    <div>M2 6BA</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={4} className={Style.borderLeft}>
                            <div className={Style.footerTitle}>Get in touch</div>
                            <ul>
                                <li><FontAwesomeIcon icon={faEnvelope} className={Style.navIcon}/><span onClick={handleEmail} className={Style.footerLink}>info@thermly.co.uk</span></li>
                                <li><FontAwesomeIcon icon={faExternalLinkAlt} className={Style.navIcon}/><a href='https://www.thermly.co.uk' target='_blank' rel="noopener noreferrer" style={{color: "white"}}>www.thermly.co.uk</a></li>
                            </ul>
                        </Col>
                        <Col sm={4} className={Style.borderLeft}>
                            <div className={Style.footerTitle}>Legal</div>
                            <ul>
                                <li><FontAwesomeIcon icon={faExternalLinkAlt} className={Style.navIcon}/><a href='https://platform.c-path.com/terms.pdf' target='_blank' rel="noopener noreferrer" style={{color: "white"}}>Terms of service</a></li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                : null
            }
        </Fragment>
    )
};

export default Footer
