import React from 'react';
import Style from './Modal.module.css'

const Modal = (props) => {
    if(!props.show) return null
    return (
        <div className={Style.modalBlock}>
            <div className={Style.modal} id="modal">
                <div title="Close" className={Style.modalClose} onClick={props.closeModal}>Close</div>
                <h3>{props.title}</h3>
                <div className={Style.content}>{props.children}</div>
            </div>
        </div>
    )
}

export default Modal
