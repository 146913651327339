import React from 'react'

const About = () => {
    return (
        <div className='loadingContainer'>
            Coming soon
        </div>
    )
}

export default About;