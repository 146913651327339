const UpdateSource = async (data, assumptions, apiProvider) => {
    let postcodeUrl = assumptions.summary.measureSelection === 'wh' ? 'postcode/to_c' : 'postcode';
    let lsoaUrl = assumptions.summary.measureSelection === 'wh' ? 'lsoa/to_c' : 'lsoa';
    let dataLsoaFull = {};
    let dataPostcodeFull = {};
    let params = data.user.userType + '/' + data.user.userArea;

    if(assumptions.summary.filterSelection === 'custom') {
        dataLsoaFull = await apiProvider.getSingle(lsoaUrl + '/custom', params + '/' + assumptions.summary.gas + '/' + assumptions.summary.epc + '/' + assumptions.summary.propertyType + '/' + assumptions.summary.tenureType, data.user.accessToken);
        dataPostcodeFull = await apiProvider.getSingle(postcodeUrl + '/custom', params + '/' + assumptions.summary.gas + '/' + assumptions.summary.epc + '/' + assumptions.summary.propertyType + '/' + assumptions.summary.tenureType, data.user.accessToken);
    } else {
        dataLsoaFull = await apiProvider.getSingle(lsoaUrl, params, data.user.accessToken);
        dataPostcodeFull = await apiProvider.getSingle(postcodeUrl, params, data.user.accessToken);
    };

    dataLsoaFull.map(lsoa => {
        let x = data.lsoaFuelPoverty.filter(fp => fp.lsoa === lsoa.lsoa);
        if(x.length === 0) x = {0: {fuel_poverty: 0}}; // Missing Buckinghamshire and Folkestone and Hythe from fuel poverty data
        lsoa.percent_fuel_poverty = x[0].percent_fuel_poverty;
        return lsoa
    });
    dataPostcodeFull.map(postcode => {
        let x = data.lsoaFuelPoverty.filter(fp => fp.lsoa === postcode.lsoa);
        if(x.length === 0) x = {0: {fuel_poverty: 0}} // Missing Buckinghamshire and Folkestone and Hythe from fuel poverty data
        postcode.percent_fuel_poverty = x[0].percent_fuel_poverty;
        return postcode
    });

    return {
        dataLsoaFull: dataLsoaFull,
        dataPostcodeFull: dataPostcodeFull
    };
};

export default UpdateSource;