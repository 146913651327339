import React, { useState, createContext } from 'react';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [monthNames[Number(month - 1)], year].join(' ');
};

export const AssumptionsContext = createContext();
export const AssumptionsContextProvider = props => {
    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const [assumptions, setAssumptions] = useState(
        {
            summary: {
                name: "",
                owner: "",
                budget: "",
                focus: "cost",
                population: "empty",
                method: "topdown",
                measureSelection: "custom",
                filterSelection: 'standard',
                benchmarkKpi: "owner",
                managementCost: 0,
                contingencyCost: 0,
                auxiliaryCost: 0,
                start: new Date(y, m, 1),
                end: new Date(y, m + 1, 1),
                phasing: 'liner',
                benchmarkTimeline: 'lifetime',
                uptake: 100,
                ownerFunding: 100,
                otherFunding: 0,
                residentFunding: 0,
                mapSelected: [],
                measuresSelected: [],
                selectedPostcodes: [],
                summarySelection: 'project',
                gas: 'gasBoth',
                epc: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
                propertyType: ['Bungalow', 'Flat', 'House', 'Maisonette', 'Park home'],
                tenureType: ['owner-occupied', 'rental (private)', 'rental (social)', 'unclassified']
            },
            measures: [
                {id: 'ewi', text: 'External wall insulation', checked: '', group: 'wall', disabled:false}, 
                {id: 'cwi', text: 'Cavity wall insulation', checked: '', group: 'wall', disabled:false},
                {id: 'cwihtt', text: 'Hard to treat cavity insulation', checked: '', group: 'wall', disabled:false},
                {id: 'pwi', text: 'Party wall insulation', checked: '', group: 'wall', disabled:false},
                {id: 'lif', text: 'Loft insulation', checked: '', group: 'roof', disabled:false},
                {id: 'litu', text: 'Loft insulation - top up', checked: '', group: 'roof', disabled:false},
                {id: 'fri', text: 'Flat roof insulation', checked: '', group: 'roof', disabled:false},
                {id: 'ftghs', text: 'First time GCH', checked: '', group: 'heating', disabled:false},
                {id: 'gbr', text: 'Gas boiler replacement', checked: '', group: 'heating', disabled:false},
                {id: 'ashp', text: 'Air-source heat pump', checked: '', group: 'heating', disabled:false},
                {id: 'pv', text: 'Photovoltaic panels', checked: '', group: 'roof', disabled:false},
                {id: 'sc', text: 'Smart controls', checked: '', group: 'other', disabled:false},
                {id: 'gl', text: 'Glazing', checked: '', group: 'other', disabled:false},
                {id: 'st', text: 'Solar thermal', checked: '', group: 'heating', disabled:false},
                {id: 'bat', text: 'Battery', checked: '', group: 'other', disabled:false},
                {id: 'light', text: 'Lighting', checked: '', group: 'other', disabled:false}
            ],
            gasFilters: [
                {id: 'gasBoth', text: 'Both', checked: true, group: 'gas', disabled: false},
                {id: 'gasOn', text: 'On gas', checked: '', group: 'gas', disabled: false},
                {id: 'gasOff', text: 'Off gas', checked: '', group: 'gas', disabled: false},
            ],
            epcFilters: [
                {id: 'A', text: 'A', checked: true, group: 'epc', disabled: false},
                {id: 'B', text: 'B', checked: true, group: 'epc', disabled: false},
                {id: 'C', text: 'C', checked: true, group: 'epc', disabled: false},
                {id: 'D', text: 'D', checked: true, group: 'epc', disabled: false},
                {id: 'E', text: 'E', checked: true, group: 'epc', disabled: false},
                {id: 'F', text: 'F', checked: true, group: 'epc', disabled: false},
                {id: 'G', text: 'G', checked: true, group: 'epc', disabled: false},
            ],
            propertyTypeFilters: [
                {id: 'Bungalow', text: 'Bungalow', checked: true, group: 'propertyType', disabled: false},
                {id: 'Flat', text: 'Flat', checked: true, group: 'propertyType', disabled: false},
                {id: 'House', text: 'House', checked: true, group: 'propertyType', disabled: false},
                {id: 'Maisonette', text: 'Maisonette', checked: true, group: 'propertyType', disabled: false},
                {id: 'Park home', text: 'Park home', checked: true, group: 'propertyType', disabled: false},
            ],
            tenureTypeFilters: [
                {id: 'owner-occupied', text: 'Owner-occupied', checked: true, group: 'tenureType', disabled: false},
                {id: 'rental (private)', text: 'Private rental', checked: true, group: 'tenureType', disabled: false},
                {id: 'rental (social)', text: 'Social rental', checked: true, group: 'tenureType', disabled: false},
                {id: 'unclassified', text: 'Unclassified', checked: true, group: 'tenureType', disabled: false},
            ],
            measuresSelected: [],
            graphData: {
                projectLength: 2,
                months: [formatDate(new Date(y, m, 1)), formatDate(new Date(y, m + 1, 1))],
                selections: {
                    calculation: 'cumulative',
                    yAxis: 'cost',
                    plotMeasures: 'all',
                }
            },
            installationProfile: {total: [0,0]}
        }
    );
    return(
        <AssumptionsContext.Provider value={[assumptions, setAssumptions]}>
            {props.children}
        </AssumptionsContext.Provider>
    )
}