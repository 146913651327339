import React, { useState, useRef } from 'react'
import Style from './Layout.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Accordion = ({ title, component }) => {

    const content = useRef(null);
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [chevron, setChevron] = useState(faChevronRight);

    const toggleAccordion = () => {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setChevron(setActive === "active" ? faChevronRight : faChevronDown);
    }

    return (
        <div>
            <div className={Style.accordionSection}>
                <button 
                    className={`${Style.accordion} ${setActive === "active" ? Style.active : ""}`}
                    onClick={toggleAccordion}
                >
                    <p className={`${Style.accordionTitle}`}>
                        {title}
                    </p>
                    <FontAwesomeIcon icon={chevron} className={`${Style.accordionIcon}`} />
                </button>
                <div 
                    className={Style.accordionContent}
                    ref={content}
                    style={{ maxHeight: `${setHeight}` }}
                >
                    <div className={Style.accordionBody}>
                        {component}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion  