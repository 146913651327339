import React, {useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const [hover, setHover] = useState(false); 
  
  const buttonContainer = {
    width: "8rem",
    backgroundColor: "var(--action-color)",
    borderRadius: "10px",
    display: "flex",
    margin: "0 0.5rem",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  };

  const buttonStyle =  ({hover}) => ({
    textAlign: "center",
    width: "100%",
    color: "white",
    padding: "0.45rem",
    cursor: hover ? "pointer" : "auto",
  });

  return (
    <div style={buttonContainer}>
      <h3 
        style={buttonStyle({hover})} 
        className="font-primary" 
        onClick={() => loginWithRedirect()}
        onPointerOver={()=> setHover(true)}
        onPointerOut={() => setHover(false)}
      >
        Log In
      </h3>
    </div>
  );
};

export default LoginButton;
