const CalculateTableData = (tableData, tableAssumptions, CalculateTotalsMeasures) => {
    if(tableData.length === 0) return [];
    let rows = [];
    Object.values(tableData).forEach(pc => {
        let row = {
            ...pc,
            id: pc.postcode,
        };

        let totalCount = pc.measures.reduce((count, x) => {return count += (x.count)}, 0);
        row.totalCount = totalCount;

        let rowBreakdown = {};
        Object.values(pc.measures).forEach(measure => {
            rowBreakdown[measure.id + '_count'] = measure.count;
            rowBreakdown[measure.id + '_cost'] = measure.cost;
            rowBreakdown[measure.id + '_energy'] = measure.energy;
            rowBreakdown[measure.id + '_carbon'] = measure.carbon;
        });        
        rows.push({...row, ...rowBreakdown});
    });
    return(rows);
}

export default CalculateTableData