const CalculateTotalsMeasures = (data, assumptions, uptake) => {
    
    let measures = assumptions.measuresSelected;
    let rows = [];

    measures.forEach(measure => {
        let totalCost = Object.values(data).reduce((cost, lsoa) => {
            lsoa.measures.map(x => {return(x.id === measure ? cost += (x.cost * uptake/100) : 0)});
            return cost;
        }, 0);
        let totalEnergy = Object.values(data).reduce((energy, lsoa) => {
            lsoa.measures.map(x => {return(x.id === measure ? energy += (x.energy * uptake/100) : 0)});
            return energy;
        }, 0);
        let totalCarbon = Object.values(data).reduce((carbon, lsoa) => {
            lsoa.measures.map(x => {return(x.id === measure ? carbon += (x.carbon * uptake/100) : 0)});
            return carbon;
        }, 0);
        let totalCount = Object.values(data).reduce((count, lsoa) => {
            lsoa.measures.map(x => {return(x.id === measure ? count += (x.count * uptake/100) : 0)});
            return count;
        }, 0);
        let measureName = assumptions.measures
            .filter(x => measure === x.id)
            .map(x => x.text);
        rows.push(
            {
                id: measureName[0],
                measure: measure,
                count: Math.round(totalCount),
                energy: Math.round(totalEnergy),
                carbon: Math.round(totalCarbon),
                cost: Math.round(totalCost),
                averageCost: Math.round(isNaN(totalCost / totalCount) ? 0 : totalCost / totalCount)
            }
        );
    });

    return(rows);
};

export default CalculateTotalsMeasures;