
import L from "leaflet";
import { useEffect } from "react";


function Legend({ map, focus }) {
    useEffect(() => {
      if (map) {
        const legend = L.control({ position: "bottomleft" });
  
        legend.onAdd = () => {
            const div = L.DomUtil.create("div", "info legend");
            let labels = [
                '<div><div style="background: rgb(51,255,0,0.3); height: 14px; width: 14px; display: inline-block; margin-right: 0.5rem"></div><div style="display: inline-block">Areas with lowest relative opportunity</div></div>',
                '<div><div style="background: rgb(255,0,0,0.3); height: 14px; width: 14px; display: inline-block; margin-right: 0.5rem"></div><div style="display: inline-block">Areas with highest relative opportunity</div></div>'
            ];
            div.innerHTML = '<div style="background: #fff; padding: 0.5rem; border-radius: 10px">' + labels.join("<br>") + '</div>';
            return div;
        };
  
        legend.addTo(map);
      }
    }, [map]);
    return null;
  }

export default Legend
