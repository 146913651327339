import React, {useState, useContext} from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import Style from './Summary.module.css';
import {DataContext} from '../../../../../context/DataContext';
import {MapContext} from '../../../../../context/MapContext';
import {AssumptionsContext} from '../../../../../context/AssumptionsContext';
import FilterData from '../../../../../logic/FilterData';
import CalculateTotals from '../../../../../logic/CalculateTotals';
import CalculateTotalsMeasures from '../../../../../logic/CalculateTotalsMeasures';

const Summary = () => {
    const [data] = useContext(DataContext);
    const [map] = useContext(MapContext);
    const [assumptions, setAssumptions] = useContext(AssumptionsContext);
    const [selected, setSelected] = useState({project: Style.selected, lsoa: ''});

    // Input handlers
    const inputHandler = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setAssumptions(prev => {
            prev.summary[name] = value;
            return ({...prev})
        });
    };

    const toggleShow = (e) => {
        let name = e.target.name;
        setAssumptions(prev => {
            prev.summary.summarySelection = name;
            return ({...prev})
        });
        setSelected(prev => {
            prev.project = name === 'project' ? Style.selected : '';
            prev.lsoa = name === 'lsoa' ? Style.selected : '';
            return({...prev});
        })
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
    });

    // Get data
    let dataTotals = {};
    let dataTotalsMeasures = {};
    let dataStatic = {}; // Only used here, but probably should be calculated elsewhere
    if(assumptions.summary.summarySelection === 'project') {
        dataTotals = data.dataTotals;
        dataTotalsMeasures = data.dataTotalsOpportunityMeasure;
        dataStatic = data.dataLsoaStatic;
    }
    else {
        let lsoaData = data.dataLsoaFull;
        let geoSelected = map.hovered;
        let measureSelected = assumptions.measuresSelected;

        let filtered = FilterData(geoSelected, measureSelected, lsoaData);
        dataTotals = CalculateTotals(filtered, assumptions, 100);
        dataTotalsMeasures = CalculateTotalsMeasures(filtered, assumptions, 100);
        dataStatic = Object.values(data.dataLsoaStatic).filter(f => {return(geoSelected.includes(f.lsoa) ? f.lsoa : false)});
    };

    let employmentImpact = dataTotalsMeasures.reduce((impact, measure) => {
        data.employmentImpact
            .filter(x => measure.measure === x.measure)
            .map(x => impact += Math.round((Number(x.fte_days) * measure.count) / 228));
        return(impact);
    }, 0);

    let totalUPRN = Object.values(dataStatic).reduce((count, lsoa) => {
        map.mapSelected.includes(lsoa.lsoa) ? count += Number(lsoa.uprn_count) : count += 0;
        return count;
    }, 0);
    let totalEPC = Object.values(dataStatic).reduce((count, lsoa) => {
        map.mapSelected.includes(lsoa.lsoa) ? count += Number(lsoa.epc_count) : count += 0;
        return count;
    }, 0);

    // Components
    const SummaryItem = (props) => {
        let {name, value, paddingBottom, breakdown} = props;
        let rowStyle = paddingBottom ? `${Style.summaryRow} ${Style.paddingBottom}` : Style.summaryRow;
        let suffix = name === '' ? '' : ':';
        let rowName = name + suffix;
        let leftColumn = breakdown ? '' : Style.leftColumn;
        return(
            <div className={rowStyle} key={rowName + 'Row'}>
                <div className={leftColumn} key={rowName + 'Left'} dangerouslySetInnerHTML={{__html: rowName}}></div>
                <div className={Style.rightColumn} key={rowName + 'Right'}>{value}</div>
            </div>
        );
    };

    const SummaryBreakdown = props => {
        let {measures, kpi} = props;
        let rows = [];
        let prefix = kpi === 'cost' ? '£' : '';
        measures.forEach(measure => {
            if(!['Management costs', 'Contingency costs', 'Auxiliary costs'].includes(measure.id))
            rows.push(<SummaryItem name={measure.id} value={prefix + measure[kpi].toLocaleString(undefined, {maximumFractionDigits:0})} paddingBottom={false} breakdown={true} key={measure.id}/>)
        });
        return rows;
    };

    return (
        <div className={Style.container}>
            <Container style={{marginTop: "0"}}>
                <h3>Scale of opportunity</h3>
                <Row>
                    <Col md={12}>
                        <ul className={Style.navList}>
                            <li><button className ={`${Style.item} ${selected.project}`}  name='project' onClick={toggleShow} data-tip data-for="projectTip">Project</button></li>
                            <li><button className ={`${Style.item} ${selected.lsoa}`}  name='lsoa' onClick={toggleShow} data-tip data-for="lsoaTip">LSOA</button></li>
                        </ul>
                        <ReactTooltip id="projectTip" place="top" effect="solid">View project-level summary</ReactTooltip>
                        <ReactTooltip id="lsoaTip" place="top" effect="solid">View individual LSOA</ReactTooltip>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={Style.summaryContainer}>
                        <div className={`${Style.paddingBottom}`}>
                            {
                                assumptions.summary.summarySelection === 'project'
                                ? 'Click on an LSOA to add it to or remove it from the project'
                                : 'Hover over an LSOA to view local opportunity'
                            }
                        </div>
                        <div>Map colouring</div>
                        <label htmlFor="focus" className={`${Style.label}`} data-tip data-for="focusTip">
                            <select 
                                name="focus"
                                value={assumptions.summary.focus}
                                onChange={inputHandler}
                            >
                                <option value="cost">Cost opportunity</option>
                                <option value="energy">Energy saving opportunity</option>
                                <option value="carbon">Carbon saving opportunity</option>
                                <option value="fuel_poverty_ranking">Likelihood of fuel poverty</option>
                            </select>
                        </label>
                        <ReactTooltip id="focusTip" place="top" effect="solid">Select the metric by which the map should be coloured - colouring relates to scale of opportunity</ReactTooltip>
                        <div className={`${Style.marginBottom} ${Style.paddingBottom} ${Style.underscore}`}></div>
                        <div>
                            <SummaryItem name='LSOAs selected' value={dataTotals.selectedLsoa.toLocaleString()} paddingBottom={false} />
                            <SummaryItem name='Domestic properties' value={totalUPRN.toLocaleString()} paddingBottom={false} />
                            <SummaryItem name='EPC coverage in area' value={totalEPC > 0 ? parseFloat(100 * totalEPC / totalUPRN).toFixed(0)+"%" : 0} paddingBottom={false} />
                            <SummaryItem name='Properties in fuel poverty' value={dataTotals.totalFuelPoverty.toLocaleString()} paddingBottom={true} data-tip data-for="fpTip"/>
                            <SummaryItem name='Targeted properties' value={dataTotals.totalTargetedProperties.toLocaleString()} paddingBottom={false} />
                            <SummaryItem name='Measures selected' value={dataTotals.selectedMeasures.toLocaleString()} paddingBottom={false} />
                            <SummaryItem name='Measures count' value={Math.round(dataTotals.totalCount).toLocaleString()} paddingBottom={true} />
                            <SummaryItem name='Totals' value={''} paddingBottom={false} />
                            <SummaryItem name='Cost' value={formatter.format(dataTotals.totalCost)} paddingBottom={false} breakdown={true}/>
                            <SummaryItem name='Employment impact (FTE years)' value={employmentImpact.toLocaleString()} paddingBottom={false} breakdown={true}/>
                            <SummaryItem name='Energy saving (kWh over 30yrs)' value={dataTotals.totalEnergy.toLocaleString()} paddingBottom={false} breakdown={true}/>
                            <SummaryItem name='CO<sub>2</sub> saving (kgCO<sub>2</sub> over 30yrs)' value={dataTotals.totalCarbon.toLocaleString()} paddingBottom={true} breakdown={true}/>
                            <SummaryItem name='Cost breakdown' value={''} paddingBottom={false} />
                            <SummaryBreakdown measures={dataTotalsMeasures} kpi='cost' />
                            <SummaryItem name='' value={''} paddingBottom={true} />
                            <SummaryItem name='Energy saving breakdown (kWh over 30yrs)' value={''} paddingBottom={false} />
                            <SummaryBreakdown measures={dataTotalsMeasures} kpi='energy' />
                            <SummaryItem name='' value={''} paddingBottom={true} />
                            <SummaryItem name='CO<sub>2</sub> saving breakdown (kgCO<sub>2</sub> over 30yrs)' value={''} paddingBottom={false} />
                            <SummaryBreakdown measures={dataTotalsMeasures} kpi='carbon' />

                            <ReactTooltip id="fpTip" place="top" effect="solid" html={true}>{'Estimated number of properties within selected LSOAs that are in fueal poverty.'}</ReactTooltip>
                        
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Summary;