import React, { useState, createContext } from 'react';

export const DataContext = createContext();
export const DataContextProvider = props => {
    // Rename data
    // Should have:
    // Base data - not filtered 
    // Opportunity data - filtered by measure and map
    // Project data - filtered by measure, map and table postcode selector, with uptake applied
    const [data, setData] = useState({
        dataLsoa: {},
        dataLsoaFull: {},
        dataLsoaStatic: {},
        dataPostcode: {},
        dataPostcodeFull: {},
        dataTotals: {
            totalUprn: 0,
            totalCount: 0,
            totalCostPotential: 0,
            totalCost: 0,
            managementCost: 0,
            contingencyCost: 0,
            totalCostProject: 0,
            totalCostFinal: 0,
            totalCarbon: 0,
            totalCostWall: 0,
            totalCostRoof: 0,
            totalCostHeating: 0,
            totalCostOther: 0,
            totalTargetedProperties: 0,
            averageCost: 0,
            averageCostWall: 0,
            averageCostRoof: 0,
            averageCostHeating: 0,
            averageCostOther: 0,
        },
        dataTotalsOpportunityMeasure: [],
        dataTotalsMeasure: [],
        dataTotalsKPI: {
            totalCostProject: 0,
            totalCount: 0,
            totalCarbon: 0
        },
        dataTable: [],
        geoJson: {},
        lsoaList: [],
        coords: [],
        user: {
            userType: '',
            userArea: '',
            projectList: [],
            scenarioList: [],
            loadedProject: '',
            loadedScenario: '',
            newProject: false,
            newScenario: false,
            userDetails: {},            
        },
        employmentImpact: [],
        timeline: {total: {cost: [0,0], count: [0,0]}}
    });
    return(
        <DataContext.Provider value={[data, setData]}>
            {props.children}
        </DataContext.Provider>
    )
}