import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './pages/about/About';
import Info from './pages/info/Info';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Plan from './pages/plan/Plan';
import NotFound from './pages/NotFound';
import Container from 'react-bootstrap/Container';
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import ProtectedRoute from "./auth/ProtectedRoute";
import { UserContextProvider } from './context/UserContext';
import { DataContextProvider } from './context/DataContext';
import { AssumptionsContextProvider } from './context/AssumptionsContext';
import { SliderContextProvider } from './context/SliderContext';
import { MapContextProvider } from './context/MapContext';

import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import './App.css'

const App = () => {

  return(
    <Router>
      <Auth0ProviderWithHistory>
        <UserContextProvider>
          <DataContextProvider>
            <AssumptionsContextProvider>
              <SliderContextProvider>
                <MapContextProvider>
                  <Navbar title='Thermly Hub' icon='fab fa-github'/>
                  <Container style={{backgroundColor: "rgb(255, 255, 255)"}}>
                    <Switch>
                      <Route exact path='/' component={Login}/>
                      <ProtectedRoute exact path='/Home' component={Home}/>
                      <ProtectedRoute exact path='/Plan' component={Plan}/>
                      <ProtectedRoute exact path='/About' component={About}/>
                      <ProtectedRoute exact path='/Info' component={Info}/>
                      <ProtectedRoute component={NotFound}/>
                    </Switch> 
                  </Container>
                  <Footer/>
                </MapContextProvider>
              </SliderContextProvider>
            </AssumptionsContextProvider>
          </DataContextProvider>
        </UserContextProvider>
      </Auth0ProviderWithHistory>
    </Router>
  );
}

export default App;