import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h1>Not Found</h1>
            <p className="lead">Page does not exist.</p>
        </div>
    )
};

export default NotFound;