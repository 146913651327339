const CalculateTotals = (data, assumptions, uptake) => {
    let lsoaList = [];
    Object.values(data).forEach(pc => lsoaList.push(pc.lsoa));
    
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    let totalCount = Object.values(data).reduce((count, lsoa) => {
        lsoa.measures.map(measure => count += measure.count * uptake/100)
        return count;
    }, 0);

    let totalCostPotential = Object.values(data).reduce((cost, lsoa) => {
        lsoa.measures.map(measure => cost += measure.cost * uptake/100);
        return cost;
    }, 0);
    let totalCost = totalCostPotential;

    // let totalUPRN = Object.values(data).reduce((count, lsoa) => {
    //     lsoa.measures.map(measure => count += measure.count * uptake/100)
    //     return count;
    // }, 0);

    let auxiliaryCost = assumptions.summary.auxiliaryCost;
    let managementCost = (totalCost + auxiliaryCost) * assumptions.summary.managementCost / 100;
    let contingencyCost = (totalCost + auxiliaryCost + managementCost) * assumptions.summary.contingencyCost / 100;

    let totalCostProject = totalCost + managementCost + contingencyCost + assumptions.summary.auxiliaryCost;
    let totalCostFinal = totalCostProject * assumptions.summary.ownerFunding/100;

    let totalEnergy = Object.values(data).reduce((energy, lsoa) => {
        lsoa.measures.map(measure => energy += measure.energy * uptake/100);
        return energy;
    }, 0);
    let totalCarbon = Object.values(data).reduce((carbon, lsoa) => {
        lsoa.measures.map(measure => carbon += measure.carbon * uptake/100);
        return carbon;
    }, 0);
    let totalFuelPoverty = Object.values(data).reduce((fp, lsoa) => {
        fp = fp += lsoa.uprn_count * (lsoa.percent_fuel_poverty / 100) * (uptake / 100);
        return fp;
    }, 0);
    console.log(totalFuelPoverty)

    let dataTotals = {
        totalUprn: Math.round(Object.values(data).reduce((uprn, value) => uprn + value.uprn_count, 0)),
        totalCount: Math.round(totalCount),
        totalCostPotential: Math.round(totalCostPotential),
        totalCost: Math.round(totalCost),
        managementCost: Math.round(managementCost),
        contingencyCost: Math.round(contingencyCost),
        totalCostProject: Math.round(totalCostProject),
        totalCostFinal: Math.round(totalCostFinal),
        totalEnergy: Math.round(totalEnergy),
        totalCarbon: Math.round(totalCarbon),
        totalFuelPoverty: Math.round(totalFuelPoverty),
        totalTargetedProperties: Math.round(Object.values(data).reduce((prop, value) => prop + value.property_count, 0)),
        averageCost: Math.round(isNaN(totalCost / totalCount) ? 0 : totalCost / totalCount),
        selectedLsoa: Math.round(lsoaList.filter(onlyUnique).length),
        selectedMeasures: Math.round(assumptions.measuresSelected.length),
    };

    return dataTotals;
}

export default CalculateTotals;