const UpdateMapColour = (data, measuresSelected, focus) => {

    let getColour = (d) => {
        return d === 10  ? '#FF0000' :
               d === 9  ? '#FF3300' :
               d === 8  ? '#ff6600' :
               d === 7  ? '#ff9900' :
               d === 6  ? '#FFCC00' :
               d === 5  ? '#FFFF00' :
               d === 4  ? '#ccff00' :
               d === 3  ? '#99ff00' :
               d === 2  ? '#66ff00' :
                          '#33ff00';
    }


    if(measuresSelected.length === 0) {
        let mapData = data.geoJson;
        Object.values(mapData.features).map((lsoa) => {
            let color = 0;
            lsoa.properties.color = getColour(color);
            return lsoa
        })
        return data.geoJson;
    } else {
        let measureFiltered = Object.values(data.dataLsoaFull)
            .filter((element) => 
                element.measures.some((subElement) => measuresSelected.includes(subElement.id)))
            .map(element => {
                return Object.assign({}, element, {measures : element.measures.filter(subElement => measuresSelected.includes(subElement.id))})
            });

        let valueList = []
        let totalValues = Object.values(measureFiltered).map((lsoa) => {
            if(Object.keys(lsoa).includes(focus)) {
                lsoa.totalValue = lsoa[focus];
            } else {
                let measures = lsoa.measures;
                lsoa.totalValue = measures.reduce((value, measure) => {
                    value += measure[focus]
                    return value;
                }, 0)
            }
            valueList.push(lsoa.totalValue);
            return lsoa;
        })
        
        valueList.sort((a, b) => a - b);
        let len = valueList.length;
        let deciles = [
            {decile: 1, range: [0, valueList[Math.floor(len*.1) - 1]]},
            {decile: 2, range: [valueList[Math.floor(len*.1) - 1], valueList[Math.floor(len*.2) - 1]]},
            {decile: 3, range: [valueList[Math.floor(len*.2) - 1], valueList[Math.floor(len*.3) - 1]]},
            {decile: 4, range: [valueList[Math.floor(len*.3) - 1], valueList[Math.floor(len*.4) - 1]]},
            {decile: 5, range: [valueList[Math.floor(len*.4) - 1], valueList[Math.floor(len*.5) - 1]]},
            {decile: 6, range: [valueList[Math.floor(len*.5) - 1], valueList[Math.floor(len*.6) - 1]]},
            {decile: 7, range: [valueList[Math.floor(len*.6) - 1], valueList[Math.floor(len*.7) - 1]]},
            {decile: 8, range: [valueList[Math.floor(len*.7) - 1], valueList[Math.floor(len*.8) - 1]]},
            {decile: 9, range: [valueList[Math.floor(len*.8) - 1], valueList[Math.floor(len*.9) - 1]]},
            {decile: 10, range: [valueList[Math.floor(len*.9) - 1], valueList[Math.floor(len) - 1]]},
        ]

        Object.values(totalValues).map((lsoa) => {
            lsoa.colourCoding = deciles.findIndex((v) => lsoa.totalValue > v.range[0] && lsoa.totalValue <= v.range[1]);
            return({
                lsoa: lsoa.lsoa,
                colour: lsoa.colourCoding
            })
        })
            
        let mapData = data.geoJson;
        Object.values(mapData.features).map((lsoa) => {
            let color = 0;
            Object.values(totalValues).map((lsoaCosts) => {
                if(lsoa.properties.LSOA11NM === lsoaCosts.lsoa) color = lsoaCosts.colourCoding;
                return color
            })
            lsoa.properties.color = getColour(color);
            return lsoa
        })

        return mapData;
    }
}

export default UpdateMapColour;
